<template>
  <div>
    <div style="margin-left: 5%">
      <el-image src="https://file.testeg.cn/testeg/logo.png"></el-image>

    </div>

    <div style="margin-left: 2%">
        <el-text type="info">
            测试小工App：专注软件测试技术的分享，交流和传播<br>
            旨在为广大软件从业者，提供全方位的面试题库，在线练习，教程视频资料等。<br>
            app主要包括大厂面试题库，在线刷题看答案等功能。
        </el-text>
    </div>

    <div id="studentLogin" class="studentLogin">
      <ul style="list-style-type: none">
          <li>
              <div style="margin-top: 2%;margin-left: 20%">
                  <el-button type="success" size="large" :icon="Download">
                       <a href="https://file.testeg.cn/apk/com.testeg.testeg.apk" style="color:white">立即下载（Android）</a><br>
                  </el-button>

              </div>
          </li>
        <li>
          <h1>
            <el-text style="font-size: 25px">考试系统 - 学生端</el-text>
          </h1>
        </li>
        <li>
          <el-input v-model="phone" placeholder="请输入手机号：" style="width: 300px;height: 30px">

          </el-input>

          <el-text type="danger" size="default">&nbsp;{{ phoneTips }}</el-text>
        </li>
        <br>
        <li>
          <el-input v-model="passwd" placeholder="请输入密码：" show-password="true"
                    style="width: 300px;height: 30px" @keydown.enter="handleStudentLogin">

          </el-input>
          <el-text type="danger" size="default">&nbsp;{{ passwdTips }}</el-text>
        </li>
        <br>
        <li>
          <el-button v-loading="isload" @click="handleStudentLogin" style="width: 100px;height: 30px">
            登录
          </el-button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <router-link style="text-decoration: none;color: blanchedalmond" to="/student/register">
            <el-button link type="primary">没有账号？立即注册</el-button>
          </router-link>
        </li>
          <li>
              <div style="margin-left: 14%;margin-top: 10%">
                  <img src="https://file.testeg.cn/testeg/qrcode.png"
                       style="width: 50%;margin-left: 3%"><br>
              </div>

              <div style="margin-left: 7%">
                  <el-text type="warning" size="large">关注公众号：天麟软件测试AI
                  </el-text>
              </div>
          </li>
      </ul>
      <br>
    </div>



    </div>
    <div style="position:absolute;top:1;bottom:0;color:#8C92A4;margin-left: 13%">
    <el-text style="color:#8C92A4;">Copyright © 2023 <a href="https://testeg.cn" style="color: #8C92A4">天麟软件</a> <a
        style="color: #8C92A4" href="https://testeg.cn">testeg.cn</a> All Rights Reserved
    </el-text>
    <a href="https://beian.miit.gov.cn" style="color:#8C92A4;padding: 20px">粤ICP备2023068751号</a>

    <a href="https://file.testeg.cn/testeg/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.txt"
       style="color:#8C92A4">《用户协议》</a>和
    <a href="https://fihttps://file.testeg.cn/testeg/%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.txt"
       style="color:#8C92A4">《隐私协议》</a>
  </div>
</template>

<script>
// import {ElMessage} from "element-plus";
import { Download } from '@element-plus/icons-vue'
export default {

  name: "StudentLogin",
  setup() {return {Download}},
  data() {
    return {
      phone: "",
      passwd: "",
      phoneTips: "",
      passwdTips: "",
      isLogin: false,
      loginTips: "",
      loginState: null,
      isload: false,
    }
  },
  methods: {
    checkInput() {
      if (this.passwd && this.phone.length === 11) {
        this.isLogin = true
        this.isload = true
      } else {
        this.isLogin = false
        this.isload = false
      }
    },

    clearTips() {
      this.phoneTips = ""
      this.passwdTips = ""
    },

    handleStudentLogin() {
      this.clearTips()
      this.checkInput()
      if (this.isLogin) {

        this.$axios({
          method: "post",
          url: "/api/student/login",
          data: {
            phone: this.phone,
            passwd: this.passwd
          }
        }).then((response) => {
          if (response.data.code === 0) {
            this.$message.success({
              message: response.data.message,
              center: true
            })
            this.loginState = 0
            sessionStorage.setItem("isLogin", true)
            this.$router.push({name: "StudentHome"})

          } else {
            this.$message.error(response.data.message)
          }
          this.isload = false
        })
      } else if (this.phone.length === 0) {
        console.log(1111)
        this.phoneTips = "手机号不能为空"
      } else if (this.phone.length !== 11) {
        this.phoneTips = "手机号长度必须为11位"
      } else if (this.passwd.length === 0) {
        this.passwdTips = "密码不能为空"
      }
    }
  }
}
</script>
<style>
.background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  /*top:10%;*/
  /*left:10%;*/
  filter: blur(15px);
}

.studentLogin {
  z-index: 1;
  position: absolute;
  top: 18%;
  left: 35%;
}

.studentPra {
  position: absolute;
  top: 1%;
  left: 80%;
}

.loginTip {
  color: red;
  font-size: 15px;
  font-weight: 900;
  text-align: center;
}

a {
  text-decoration: none;
  color: blue;
}
</style>