<template>
  <router-view/>
  
</template>

<script>
import StudentLogin from './components/StudentLogin.vue'
import StudentRegister from "@/components/StudentRegister.vue";

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    StudentLogin, StudentRegister
  },
  methods: {
    handleStudentLogin() {

    }
  }
}
</script>

<style>
.desc {
  text-align: center;
}

</style>
