import {createRouter, createWebHistory} from "vue-router";
// import StudentLogin from "@/components/StudentLogin.vue";
// import StudentRegister from "@/components/StudentRegister.vue";
const routes = [
    // {
    //     path: "",
    //     redirect: "/home",
    // },
    // {
    //     path: "/",
    //     redirect: "/home"
    // },
    // {
    //     path: "/home",
    //     name: "Home",
    //     component: () => import('@/components/Home.vue')
    // },

    {
        path: "",
        redirect: "/student/login",
    },
    {
        path: "/",
        redirect: "/student/login"
    },
    {
        path: "/student",
        redirect: "/student/login"
    },

    {
        path: "/student/login",
        name: "StudentLogin",
        component: () => import('@/components/StudentLogin.vue')
    },
    {
        path: "/student/register",
        name: "StudentRegister",
        component: () => import('@/components/StudentRegister.vue')
    },
    {
        path: "/student/home",
        name: "StudentHome",
        component: () => import('@/components/StudentHome.vue')
    },
    {
        path: "/exam/detail",
        name: "ExamDetail",
        component: () => import('@/components/ExamDetail.vue')
    },
    {
        path: "/exam/createExam",
        name: "CreateExam",
        component: () => import('@/components/CreateExam.vue')
    },
    {
        path: "/exam/answerdetail",
        name: "AnswerDetail",
        component: () => import('@/components/AnswerDetail.vue')
    },
    {
        path: "/exam/gradeDetail",
        name: "GradeDetail",
        component: () => import('@/components/GradeDetail.vue')
    },
    {
        path: "/exam/customexam",
        name: "CustomExam",
        component: () => import('@/components/CustomExam.vue')
    },
    {
        path: "/teacher",
        redirect: "/teacher/login"
    },
    {
        path: "/teacher/login",
        name: "TeacherLogin",
        component: () => import('@/components/TeacherLogin.vue')
    },
    {
        path: "/teacher/home",
        name: "TeacherHome",
        component: () => import('@/components/TeacherHome.vue')
    },
    {
        path: "/download/apkdownload",
        name: "ApkDownload",
        component: () => import('@/components/ApkDownload.vue')
    },
    {
        path: "/jzgrade/query",
        name: "JzUserQuery",
        component: () => import('@/components/JzUserQuery.vue')
    },
    {
        path: "/jzgrade/insert",
        name: "JzUserGradeInsert",
        component: () => import('@/components/JzUserGradeInsert.vue')
    },

]
const routerHistory = createWebHistory()
const router = createRouter({
    // mode:history,
    history: routerHistory,
    routes
})
// console.log(routerHistory)
export default router