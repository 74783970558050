<template>
  <div id="student">
    <div id="studentRegister" class="studentRegister">
      <!--            <el-text class="registerTip">{{ tips }}</el-text>-->
      <div v-if="!registerStatus">
        <ul style="list-style-type: none">
          <li>
            <el-text>请选择班级：</el-text>
            <el-select v-model="this.clbumId" placeholder="请选择班级">
              <el-option v-for="(clbum,index) in clbumList" :key="index" :value="clbum.id">
                {{ clbum.clbumName }}
              </el-option>
            </el-select>
            <el-text el-text type="danger" size="default">&nbsp;{{ clbumTips }}</el-text>
          </li>
          <br>
          <li>
            <el-text>真实姓名：</el-text>
            <el-input v-model="name" placeholder="一定要是真实姓名哦！！"
                      style="width: 300px;height: 30px"></el-input>
            <el-text type="danger" size="default">&nbsp;
              {{ nameTips }}
            </el-text>

          </li>
          <br>
          <li>
            <el-text>真实手机：</el-text>
            <el-input v-model="phone" placeholder="一定要是真实手机号哦！！"
                      style="width: 300px;height: 30px">
            </el-input>
            <el-text type="danger" size="default">&nbsp;{{ phoneTips }}
            </el-text>

          </li>
          <br>
          <li>
            <el-text>登陆密码：</el-text>
            <el-input v-model="passwd" placeholder="请输入密码" type="password"
                      style="width: 300px;height: 30px">
            </el-input>
            <el-text type="danger" size="default">&nbsp;{{ passwdTips }}</el-text>

          </li>
          <br>
          <li>
            <el-text>确认密码：</el-text>
            <el-input v-model="confirm" placeholder="请输入确认密码" type="password"
                      style="width: 300px;height: 30px" @keydown.enter="handleStudentRegister">
            </el-input>
            <el-text type="danger" size="default">&nbsp;{{ confirmTips }}
            </el-text>
          </li>
          <br>

          <li>
            <el-button @click="handleStudentRegister"
                       style="width: 100px;height: 30px;margin-left: 20%" v-loading="isload">注册
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  name: "StudentRegister",

  data() {
    return {
      name: "",
      phone: "",
      passwd: "",
      confirm: "",
      clbumId: null,
      clbumList: [],
      registerStatus: false,
      waitTime: 3,
      tipsTime: 5,
      tips: "",
      isSubmit: false,
      nameTips: "",
      phoneTips: "",
      passwdTips: "",
      confirmTips: "",
      clbumTips: "",
      isload: false
    }
  },

  mounted() {
    this.getClbumList()
  },


  methods: {
    getClbumList() {
      this.$axios({
        method: "get",
        url: "/api/clbum/getClbumList"
      }).then((response) => {

        // this.clbumList=response.data.payload;
        this.clbumList = response.data
      })
    },

    checkInput() {
      if (this.name && this.passwd && this.phone.length === 11 && this.confirm === this.passwd && this.clbumId !== null) {
        this.isSubmit = true
      }
    },

    clearTips() {
      this.nameTips = ""
      this.phoneTips = ""
      this.passwdTips = ""
      this.confirmTips = ""
      this.clbumTips = ""
    },


    handleStudentRegister() {
      this.clearTips()
      this.checkInput()
      if (this.isSubmit) {
        this.isload = true
        this.$axios({
          method: "post",
          url: "/api/student/register",
          data: {
            name: this.name,
            phone: this.phone,
            passwd: this.passwd,
            confirm: this.confirm,
            clbumId: this.clbumId
          },
        }).then((response) => {
          if (response.data.message === "注册成功" && response.data.studentId != 0) {
            this.registerStatus = true
            var it = setInterval(() => {
              this.$message.success({
                message: response.data.message + "," + this.waitTime + "秒后跳转到登录页！",
                duration: 1000,
                center: true

              })
              // this.tips =
              this.waitTime -= 1
              if (this.waitTime === 0) {
                window.clearInterval(it)
                this.$router.push("/student/login")
              }
            }, 1000)
          } else {
            this.$message.error(response.data.message)
          }
          this.isload = false
        })
      } else if (this.clbumId === null) {
        this.clbumTips = "班级不能为空"
      } else if (this.name.length === 0) {
        this.nameTips = "姓名不能为空"
      } else if (this.phone.length !== 11) {
        this.phoneTips = "手机号长度必须为11位"
      } else if (this.passwd.length === 0) {
        this.passwdTips = "密码不能为空"
      } else if (this.confirm !== this.passwd) {
        this.confirmTips = "密码和确认密码不一致"
      }

    }
  },

}

</script>
<style>
.background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  /*top:10%;*/
  /*left:10%;*/
  filter: blur(15px);
}

.studentRegister {
  z-index: 1;
  position: absolute;
  /*text-align-last: justify;*/
  /*text-align: justify;*/
  top: 30%;
  left: 30%;
}

.registerTip {
  color: red;
  text-align: center;
}
</style>