import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import axios from "axios";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
// 部署到tomcat
axios.defaults.baseURL = window.location.host === "www.testeg.cn" ? "https://www.testeg.cn" : "https://testeg.cn"
router.beforeEach((to, from, next) => {
    const isLogin = !!sessionStorage.getItem("isLogin");
    if (to.path === "/download/apkdownload" || to.path === "/student/login" || to.path === "/teacher/login" || to.path === "/student/register" || to.path === "/"
        || to.path === "/jzgrade/insert"  || to.path === "/jzgrade/query"
    ) {
        // if (to.path === "" || to.path === "/" || to.path === "/home") {
        next()
    } else if (!isLogin) {
        next({name: "StudentLogin"})
    } else {
        next()
    }
})
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.config.globalProperties.$axios = axios
// createApp(App).use(router).use(axios).mount('#app')
app.mount("#app")
